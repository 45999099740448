import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'

const photoNavLinkData = require('../../content/photoNavLinkData.js')

// use replace to avoid nav history when switching tabs
function PhotoNavButton(props) {
  if (props.isSelected) {
    return <button className="special xsmall">{props.buttonName}</button>
  }
  return (
    <button
      className="xsmall"
      onClick={() => window.location.replace(props.path)}
    >
      {props.buttonName}
    </button>
  )
}

const PhotoNav = ({ relativeDirectory, selectedLink }) => {
  let photoData = photoNavLinkData[relativeDirectory] || {}
  let navList = photoData['albumList'] || []
  let title = photoData['title'] ? (
    <span style={{ whiteSpace: 'nowrap' }}>{photoData['title']}</span>
  ) : (
    ''
  )

  return (
    <div>
      <button
        className="right xsmall photos__closeButton"
        onClick={() => navigate(-1)}
        aria-label="close"
      />
      <div className="photo-nav">
        {title}
        {navList.map((item, index) => (
          <PhotoNavButton
            isSelected={item === selectedLink}
            buttonName={item}
            path={'/' + relativeDirectory + '/' + item}
          />
        ))}
      </div>
    </div>
  )
}

export default PhotoNav
