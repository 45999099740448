import { graphql } from 'gatsby'
import React from 'react'
import Gallery from '@browniebroke/gatsby-image-gallery'
import PhotoNav from '../components/images/photoNav'
import { Link } from 'gatsby'

import '../assets/scss/main.scss'

const PhotoAlbumTemplate = ({ data, classes, pageContext }) => {
  // `images` is an array of objects with `thumb` and `full`
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)

  return (
    <div id="photo-album">
      <PhotoNav
        relativeDirectory={pageContext.relativeDirectory}
        selectedLink={pageContext.selectedLink}
      />
      <div className="gallery">
        <Gallery images={images} />
      </div>
      <Link to="/" className="logo" />
    </div>
  )
}

export const pageQuery = graphql`
  query ($relativePath: String!) {
    allFile(
      filter: {
        relativeDirectory: { eq: $relativePath }
        ext: {}
        id: {}
        gid: {}
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 350
              height: 350
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`

export default PhotoAlbumTemplate
